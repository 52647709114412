.not-found {
    width: 100%;
    z-index: 10;
    background: linear-gradient(#e2ccfe, #FAF8FF);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: fixed;
    padding: 2vw;
    flex-direction: column;
}

svg#freepik_stories-404-error-with-portals:not(.animated) .animable {
    opacity: 0;
}

svg#freepik_stories-404-error-with-portals.animated #freepik--background-complete--inject-139 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn, 1.5s Infinite linear floating;
    animation-delay: 0s, 1s;
}

svg#freepik_stories-404-error-with-portals.animated #freepik--Stars--inject-139 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay: 0s;
}

svg#freepik_stories-404-error-with-portals.animated #freepik--portal-4--inject-139 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight, 1.5s Infinite linear wind;
    animation-delay: 0s, 1s;
}

svg#freepik_stories-404-error-with-portals.animated #freepik--portal-3--inject-139 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown;
    animation-delay: 0s;
}

svg#freepik_stories-404-error-with-portals.animated #freepik--portal-2--inject-139 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp, 1.5s Infinite linear wind;
    animation-delay: 0s, 1s;
}

svg#freepik_stories-404-error-with-portals.animated #freepik--portal-1--inject-139 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn, 1.5s Infinite linear wind;
    animation-delay: 0s, 1s;
}

svg#freepik_stories-404-error-with-portals.animated #freepik--Texts--inject-139 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut, 1.5s Infinite linear heartbeat;
    animation-delay: 0s, 1s;
}

svg#freepik_stories-404-error-with-portals.animated #freepik--character-part-2--inject-139 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedLeft, 1.5s Infinite linear shake;
    animation-delay: 0s, 1s;
}

svg#freepik_stories-404-error-with-portals.animated #freepik--character-part-1--inject-139 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown, 1.5s Infinite linear shake;
    animation-delay: 0s, 1s;
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes floating {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes zoomOut {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes wind {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(1deg);
    }

    75% {
        transform: rotate(-1deg);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: inherit;
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.1);
    }

    30% {
        transform: scale(1);
    }

    40% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    60% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lightSpeedLeft {
    from {
        transform: translate3d(-50%, 0, 0) skewX(20deg);
        opacity: 0;
    }

    60% {
        transform: skewX(-10deg);
        opacity: 1;
    }

    80% {
        transform: skewX(2deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}


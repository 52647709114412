@-webkit-keyframes pulse {
  0% {
    opacity: 1;
  }
  16.666% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 1;
  }
  16.666% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  16.666% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.test{
  background: linear-gradient(45deg, #F4FAFF, #d3dff0, #becbdd, #8394a7, #63748b, #435265);

}

.triangles {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 81px;
  width: 90px;
  position: relative;
  inset: 0;
  left: 50%;
  top: 50%;

}

.tri {
  position: absolute;
  -webkit-animation: pulse 750ms ease-in infinite;
  -moz-animation: pulse 750ms ease-in infinite;
  animation: pulse 750ms ease-in infinite;
  border-top: 27px solid #63748b;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 0px;
}
.tri.invert {
  border-top: 0px;
  border-bottom: 27px solid #5c718e;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}
.tri:nth-child(1) {
  left: 30px;
}
.tri:nth-child(2) {
  left: 15px;
  top: 27px;
  -webkit-animation-delay: -125ms;
  -moz-animation-delay: -125ms;
  animation-delay: -125ms;
}
.tri:nth-child(3) {
  left: 30px;
  top: 27px;
}
.tri:nth-child(4) {
  left: 45px;
  top: 27px;
  -webkit-animation-delay: -625ms;
  -moz-animation-delay: -625ms;
  animation-delay: -625ms;
}
.tri:nth-child(5) {
  top: 54px;
  -webkit-animation-delay: -250ms;
  -moz-animation-delay: -250ms;
  animation-delay: -250ms;
}
.tri:nth-child(6) {
  top: 54px;
  left: 15px;
  -webkit-animation-delay: -250ms;
  -moz-animation-delay: -250ms;
  animation-delay: -250ms;
}
.tri:nth-child(7) {
  top: 54px;
  left: 30px;
  -webkit-animation-delay: -375ms;
  -moz-animation-delay: -375ms;
  animation-delay: -375ms;
}
.tri:nth-child(8) {
  top: 54px;
  left: 45px;
  -webkit-animation-delay: -500ms;
  -moz-animation-delay: -500ms;
  animation-delay: -500ms;
}
.tri:nth-child(9) {
  top: 54px;
  left: 60px;
  -webkit-animation-delay: -500ms;
  -moz-animation-delay: -500ms;
  animation-delay: -500ms;
}






.container .for-loading {
  width: 300px;
  height: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.container .loader {
  width: 20vw;
  max-height: 90vh;
  transform-origin: 50% 50%;
  overflow: visible;
  transform: scale(.8);
}

.container .ci1 {
  fill: #9A91D9;
  animation: toBig 3s infinite -1.5s;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

.container .ciw {
  transform-box: fill-box;
  transform-origin: 50% 50%;
  animation: breath 3s infinite;
}

.container .ci2 {
  fill: #9A91D9;
  animation: toBig2 3s infinite;
  transform-box: fill-box;
  transform-origin: 50% 50%;

}

.container .points {
  animation: rot 3s infinite;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(360deg);
  }

  50% {
    transform: rotate(360deg);
  }

  80% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }

}

@keyframes toBig {
  0% {
    transform: scale(1) translateX(0px);
  }

  30% {
    transform: scale(1) translateX(0px);
  }

  50% {
    transform: scale(10) translateX(-4.5px);
  }

  80% {
    transform: scale(10) translateX(-4.5px);
  }

  100% {
    transform: scale(1) translateX(0px);
  }

}

@keyframes toBig2 {
  0% {
    transform: scale(1) translateX(0px);
  }

  30% {
    transform: scale(1) translateX(0px);
  }

  50% {
    transform: scale(10) translateX(4.5px);
  }

  80% {
    transform: scale(10) translateX(4.5px);
  }

  100% {
    transform: scale(1) translateX(0px);
  }
}

@keyframes breath {
  15% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.1);
  }

  65% {
    transform: scale(1);
  }

  90% {
    transform: scale(1.1);
  }

}